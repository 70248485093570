import React, { useState } from "react";
import Layout from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainBlack,
  mainGray,
  graphik,
  larsseit,
  amatic,
  screen,
  transHover,
} from "../../components/common/variables";
import LeafOne from "../../images/case-studies/source-separation-systems/svg/leaf-1.svg";
import LeafTwo from "../../images/case-studies/source-separation-systems/svg/leaf-2.svg";
import ScrollHint from "../../components/scroll-hint";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import ArrowCurved from "../../images/case-studies/source-separation-systems/svg/curved-arrow.svg";
import LabeledArrow from "../../components/labeled-arrow";
import videoFiltering from "../../videos/source-separation-systems-product-filter.mp4";
import gifProduct from "../../images/case-studies/source-separation-systems/handsome-creative-source-separation-systems.gif";
import ContactForm from "../../components/contact-form";
import imgLaptop from "../../images/case-studies/source-separation-systems/source-top.png";

const Wrapper = styled.div`
  color: ${mainBlack};
  font-family: ${graphik};
  position: relative;

  .main {
    padding: 0 26px;
    @media ${screen.xsmall} {
      padding: 0 60px;
    }
    @media ${screen.small} {
      padding: 0 80px;
    }
    @media ${screen.medium} {
      padding: 0 7vw;
    }

    &__heading {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.17;
      margin-bottom: 5px;
      @media ${screen.medium} {
        font-size: clamp(1.1rem, calc(0.88rem + 1vw), 2.1rem);
      }
    }

    &__subheading {
      color: ${mainGray};
      font-size: 1.1rem;
      font-style: italic;
      margin-bottom: 30px;
      @media ${screen.small} {
        margin-bottom: 40px;
      }
      @media ${screen.medium} {
        font-size: 1.33rem;
      }
    }

    &__description {
      max-width: 962px;

      p {
        font-size: 1.1rem;
        margin-bottom: 34px;
        @media ${screen.small} {
          font-size: 1.15rem;
          margin-bottom: 40px;
        }
        @media ${screen.medium} {
          font-size: 1.33rem;
        }
      }
    }

    &__web-link {
      color: ${mainGray};
      display: inline-block;
      font-size: 1.1rem;
      margin: 0 0 100px 0;
      transition: ${transHover};
      @media ${screen.xsmall} {
        margin: 20px 0 130px 0;
      }
      @media ${screen.small} {
        font-size: 1.15rem;
        margin: 32px 0 175px 0;
      }
      @media ${screen.medium} {
        font-size: 1.33rem;
      }

      @media ${screen.withCursor} {
        &:hover {
          color: #69ad3f;
        }
      }
    }
  }

  .top-featured-img {
    margin: 0 -75px;
    @media ${screen.xsmall} {
      margin: 0;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .statistic {
    margin: 60px 0 40px 0;
    padding: 0 26px;
    @media ${screen.xsmall} {
      padding: 0 60px;
      margin: 100px 0;
    }
    @media ${screen.small} {
      padding: 0 80px;
      margin: 140px 0;
    }
    @media ${screen.medium} {
      padding: 0 7vw;
    }
    @media ${screen.xlarge} {
      margin: 160px 0;
    }

    &__flex {
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        flex-direction: row;
        justify-content: space-between;
      }

      .statistic-heading {
        font-weight: 500;
        line-height: 1.17;
        font-size: 2.3rem;
        font-family: ${larsseit};
        margin-bottom: 30px;
        max-width: 468px;
        @media ${screen.xsmall} {
          margin-bottom: 0;
        }
        @media ${screen.small} {
          font-size: 2.9rem;
        }
        @media ${screen.medium} {
          font-size: 3rem;
        }
      }

      .statistic-counter {
        @media ${screen.xsmall} {
          margin-left: 20px;
        }
        @media ${screen.medium} {
          padding-right: 6vw;
          margin-left: 100px;
        }

        li {
          font-family: ${larsseit};
          color: #d9d9d9;

          .num {
            font-weight: 500;
            line-height: 0.9;
            font-size: 7.7rem;
            @media ${screen.xsmall} {
              font-size: 9rem;
            }
            @media ${screen.small} {
              font-size: 10rem;
            }
            @media ${screen.medium} {
              font-size: clamp(2rem, 12.2vw, 15rem);
            }
          }

          .label {
            font-weight: 500;
            margin-bottom: 50px;
            font-size: 1.6rem;
            line-height: 1.25;
            @media ${screen.xsmall} {
              font-size: 1.8rem;
              margin-bottom: 80px;
            }
            @media ${screen.small} {
              font-size: 2.3rem;
            }
            @media ${screen.medium} {
              min-width: 490px;
              font-size: clamp(2rem, 2vw, 2.4rem);
            }

            .break {
              display: block;
            }
          }
        }
      }
    }
  }

  .responsive {
    background: #d9d9d9;
    overflow: hidden;
    padding: 60px 0;
    @media ${screen.xsmall} {
      padding: 50px 0;
    }
    @media ${screen.small} {
      padding: 70px 0;
    }
    @media ${screen.large} {
      padding: 90px 0;
    }
    @media ${screen.xlarge} {
      padding: 130px 0;
    }

    &__heading {
      text-align: center;
      font-weight: 500;
      line-height: 1.17;
      font-size: 2.3rem;
      margin: 0 auto 20px auto;
      padding: 0 26px;
      max-width: 900px;
      font-family: ${larsseit};
      @media ${screen.small} {
        font-size: 2.9rem;
      }
      @media ${screen.medium} {
        font-size: 3rem;
      }
      @media ${screen.xlarge} {
        margin: 0 auto 30px auto;
      }
    }

    &__description {
      font-size: 1.1rem;
      max-width: 930px;
      margin: 0 auto;
      text-align: center;
      padding: 0 26px;
      @media ${screen.small} {
        font-size: 1.15rem;
      }
      @media ${screen.medium} {
        font-size: 1.33rem;
      }
    }

    &__img {
      margin: 30px -140px 0 -140px;
      @media ${screen.xsmall} {
        margin: 90px -23px 0 -23px;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .filter {
    padding: 60px 26px 170px 26px;
    @media ${screen.xsmall} {
      padding: 190px 60px 50px 60px;
    }
    @media ${screen.small} {
      padding: 190px 80px 190px 80px;
    }
    @media ${screen.medium} {
      padding: 200px 7vw 120px 7vw;
    }
    @media ${screen.large} {
      padding: 200px 7vw 90px 7vw;
    }
    @media ${screen.xlarge} {
      padding: 250px 7vw 130px 7vw;
    }

    &__flex {
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        flex-direction: row;
      }

      .filter-video {
        max-width: 830px;
        color: #fff;
        width: 100%;
        position: relative;
        order: 2;
        margin: 100px 0 0 0;
        @media ${screen.xsmall} {
          margin: 0 40px 0 0;
          order: 0;
        }
        @media ${screen.small} {
          margin: 0 50px 0 0;
        }
        @media ${screen.medium} {
          margin: 0 70px 0 0;
        }
        @media ${screen.xlarge} {
          margin: 0 100px 0 0;
        }

        &__pointer-top {
          position: absolute;
          top: -95px;
          display: flex;
          @media ${screen.xsmall} {
            top: -120px;
          }

          svg {
            width: 40px;
            transform: rotate(80deg) scale(-1);
            margin: -20px 17px 0 0;
            display: block;
            @media ${screen.xsmall} {
              width: 57px;
              margin: 0 30px 0 0;
            }
          }
          p {
            color: ${mainBlack};
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1.3;
            text-transform: uppercase;
            font-family: ${amatic};
            @media ${screen.xsmall} {
              font-size: 1.55rem;
            }
          }
        }

        &__pointer-bottom {
          position: absolute;
          bottom: -60px;
          right: 50px;
          z-index: 10;
          @media ${screen.xsmall} {
            display: none;
            bottom: 100px;
            right: -220px;
          }
          @media ${screen.small} {
            display: block;
            bottom: -130px;
          }
          @media ${screen.medium} {
            bottom: 0;
            bottom: -90px;
          }
          @media ${screen.large} {
            bottom: 90px;
          }

          svg {
            width: 40px;
            margin: 0 0 -40px -61px;
            transform: scaleY(-1) rotate(-98deg);
            @media ${screen.xsmall} {
              transform: scaleY(1) rotate(0);
              width: 57px;
              margin: 0;
            }
          }

          p {
            color: ${mainBlack};
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1.3;
            text-transform: uppercase;
            font-family: ${amatic};
            @media ${screen.xsmall} {
              font-size: 1.55rem;
            }
          }
        }

        &__video {
          position: relative;

          video {
            display: block;
            width: 460px;
            height: 100%;
            outline: none;
            border: none;
            -webkit-mask-image: -webkit-radial-gradient(white, black);
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            @media ${screen.xsmall} {
              min-width: 520px;
              width: 100%;
            }
            @media ${screen.small} {
              min-width: 720px;
            }
          }
        }
      }

      .filter-text {
        &__heading {
          font-family: ${larsseit};
          font-weight: 500;
          line-height: 1.17;
          font-size: 2.3rem;
          margin: 0 0 20px 0;
          max-width: 440px;

          @media ${screen.small} {
            font-size: 2.9rem;
          }
          @media ${screen.medium} {
            font-size: 3rem;
          }
          @media ${screen.xlarge} {
            margin: 0 0 30px 0;
          }

          .break {
            display: block;
          }
        }

        &__description {
          font-size: 1.1rem;
          margin: 0 0 40px 0;
          max-width: 600px;
          @media ${screen.small} {
            font-size: 1.15rem;
          }
          @media ${screen.medium} {
            font-size: 1.33rem;
          }
        }
      }
    }
  }

  .product {
    position: relative;
    overflow: hidden;
    padding: 0 26px 110px 26px;
    @media ${screen.xsmall} {
      padding: 50px 60px;
    }
    @media ${screen.medium} {
      padding: 0 0 70px 0;
    }
    @media ${screen.large} {
      padding: 0 0 90px 0;
    }
    @media ${screen.xlarge} {
      padding: 0 0 130px 0;
    }

    .leaf-top-left {
      display: none;
      @media ${screen.xsmall} {
        display: block;
        position: absolute;
        left: -40px;
      }

      svg {
        width: 220px;
      }
    }

    .leaf-top-right {
      display: none;
      @media ${screen.xsmall} {
        display: block;
        position: absolute;
        right: -40px;
      }

      svg {
        width: 142px;
      }
    }

    &__heading {
      text-align: center;
      font-weight: 500;
      line-height: 1.17;
      font-size: 2.3rem;
      padding: 0 26px;
      font-family: ${larsseit};
      @media ${screen.small} {
        font-size: 2.9rem;
      }
      @media ${screen.medium} {
        font-size: 3rem;
      }

      .break {
        @media ${screen.xsmall} {
          display: block;
        }
      }
    }

    &__video {
      max-width: 1150px;
      color: #fff;
      width: 100%;
      margin: 0 auto;
      position: relative;
      max-width: 970px;
      @media ${screen.xsmall} {
        max-width: 880px;
      }
      @media ${screen.small} {
        max-width: 970px;
      }
      @media ${screen.medium} {
        max-width: 1150px;
      }

      .screen-container {
        padding: 20px;
        position: relative;

        &__record {
          display: block;
          width: 590px;
          pointer-events: none;
          margin: 100px 0 0 -190px;
          @media ${screen.xsmall} {
            width: 100%;
            margin: 0;
          }
        }
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .pointer-top {
        position: absolute;
        top: -28px;
        left: 50px;
        @media ${screen.xsmall} {
          left: -25px;
          top: 200px;
        }
        @media ${screen.medium} {
          left: -10px;
          top: 280px;
        }

        svg {
          width: 40px;
          transform: scaleY(-1) rotate(100deg) translateX(-120px)
            translateY(-120px);
          @media ${screen.xsmall} {
            width: 57px;
            transform: scaleX(-1) rotate(0) translateX(-100px) translateY(0);
            margin: 0;
          }
        }

        p {
          color: ${mainBlack};
          font-size: 1.2rem;
          font-weight: 700;
          line-height: 1.3;
          text-transform: uppercase;
          font-family: ${amatic};
          @media ${screen.xsmall} {
            font-size: 1.55rem;
          }
        }
      }

      .pointer-bottom {
        position: absolute;
        top: 478px;
        right: 40px;
        z-index: 10;
        @media ${screen.xsmall} {
          right: -85px;
          top: 110px;
        }
        @media ${screen.medium} {
          right: -72px;
          top: 135px;
        }

        svg {
          width: 40px;
          margin: 0 0 -50px -60px;
          transform: scaleY(-1) rotate(-95deg);
          @media ${screen.xsmall} {
            width: 57px;
            transform: scaleY(1) rotate(0);
            margin: 0;
          }
        }

        p {
          color: ${mainBlack};
          font-size: 1.2rem;
          font-weight: 700;
          line-height: 1.3;
          text-transform: uppercase;
          font-family: ${amatic};
          @media ${screen.xsmall} {
            font-size: 1.55rem;
          }
        }
      }
    }
  }

  .next-btn {
    display: flex;
    justify-content: center;
    padding: 0 0 35px 0;
    @media ${screen.small} {
      padding: 0 0 70px 0;
    }
  }
`;

const SourceSeparationSystemsPage = ({ data }) => {
  const [isCountOne, setCountOne] = useState(false);
  const [isCountTwo, setCountTwo] = useState(false);
  const [isCountThree, setCountThree] = useState(false);

  const url = typeof window !== "undefined" && window.location.href;

  return (
    <Layout colourNavbar={mainWhite}>
      <SEO
        title="Source Separation Systems | Handsome Creative"
        description="From innovative waste diversion products to finding new ways to
        connect Australians to a more sustainable world, Source Separation
        Systems are helping communities and businesses approach waste
        diversion differently in their industries."
        image={imgLaptop}
        webPageInfo={{
          name: "Source Separation Systems website by Handsome Creative",
          url,
          description:
            "From innovative waste diversion products to finding new ways to connect Australians to a more sustainable world, Source Separation Systems are helping communities and businesses approach waste diversion differently in their industries.",
        }}
      />
      <Wrapper>
        <ScrollHint />
        <div className="main">
          <h1 className="main__heading">Source Separation Systems</h1>
          <h6 className="main__subheading">Web design</h6>
          <div className="main__description">
            <p>
              From innovative waste diversion products to finding new ways to
              connect Australians to a more sustainable world, Source Separation
              Systems are helping communities and businesses approach waste
              diversion differently in their industries. The team all share a
              passion for sustainability and enthusiasm about the positive
              difference they are making in their roles across service to
              product design, logistics to in-house manufacturing.
            </p>
            <p>
              For Source Separation Systems, stakeholders are at the centre of
              its operations. “Source Separation Systems works to develop
              solutions which work for all the stakeholders we partner with,
              from the initial waste generators led by business and councils to
              the facility management groups and collection contractors, and the
              processing and recycling facilities.” With a new website, all
              stakeholders can access quality information about recycling in
              Australia and Source Separation Systems extensive product range.
            </p>
            <p>
              The new website rolled out by Handsome is easy to navigate,
              scalable, fast loading, and delivers a great experience on any
              device stakeholders use. The solution uses a WordPress/WooCommerce
              framework with a custom front-end built with HTML, CSS and
              JavaScript. The Source Separation Systems team has total
              flexibility to add/remove or modify their products and deliver a
              fast and intuitive experience for their customers.
            </p>
            <p>
              Source Separation Systems digital experience has grown
              significantly since launch, including high performance across SEO,
              visitor traffic, sales conversions, and more. Monthly sales
              through the website have increased by 47%, with over 60% growth on
              average in new users per month. Google Analytics recorded a
              significant growth in audience reach, across Australia and
              worldwide.
            </p>
          </div>

          <a
            className="main__web-link"
            href="https://www.sourceseparationsystems.com.au"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit company website"
          >
            sourceseparationsystems.com.au
          </a>
        </div>
        <div
          className="top-featured-img"
          data-sal="slide-up"
          data-sal-duration="500"
        >
          <StaticImage
            src="../../images/case-studies/source-separation-systems/source-top.png"
            alt="Source Separation Systems connect with their customers more with
              Handsome."
          />
        </div>

        <section className="statistic">
          <div className="statistic__flex">
            <h3
              className="statistic-heading"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              Source Separation Systems connect with their customers more with
              Handsome.
            </h3>

            <ul
              className="statistic-counter"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <Waypoint
                onEnter={() => {
                  if (!isCountOne) {
                    setCountOne(true);
                  }
                }}
              >
                <li>
                  {isCountOne && (
                    <p className="num">
                      <CountUp end={60} />%
                    </p>
                  )}

                  <p className="label">
                    Average increase in page
                    <span className="break">views since refresh.</span>
                  </p>
                </li>
              </Waypoint>
              <Waypoint
                onEnter={() => {
                  if (!isCountTwo) {
                    setCountTwo(true);
                  }
                }}
              >
                <li>
                  {isCountTwo && (
                    <p className="num">
                      <CountUp end={19} />x
                    </p>
                  )}

                  <p className="label">
                    Increase in
                    <span className="break">session times.</span>
                  </p>
                </li>
              </Waypoint>

              <Waypoint
                onEnter={() => {
                  if (!isCountThree) {
                    setCountThree(true);
                  }
                }}
              >
                <li>
                  {isCountThree && (
                    <p className="num">
                      <CountUp end={47} />%
                    </p>
                  )}

                  <p className="label">
                    Increase of products
                    <span className="break">purchased monthly.</span>
                  </p>
                </li>
              </Waypoint>
            </ul>
          </div>
        </section>

        <section className="responsive">
          <h4
            className="responsive__heading"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            Aligning responsive website design to deliver a better customer
            experience.
          </h4>
          <p
            className="responsive__description"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            As part of its transformation, Source Separation Systems and
            Handsome Creative ensured the design and content accommodated all
            screen types - mobile, tablet, desktop, and more - and functioned
            across various browser types.
          </p>
          <div
            className="responsive__img"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <StaticImage
              src="../../images/case-studies/source-separation-systems/handsome-creative-source-separation-systems-website-mobile.png"
              alt="Responsiveness preview"
            />
          </div>
        </section>

        <section className="filter" data-sal="slide-up" data-sal-duration="500">
          <div className="filter__flex">
            <div className="filter-video">
              <div className="filter-video__pointer-top">
                <ArrowCurved />
                <p>
                  The client required users to be able to
                  <br /> contact them with the unique
                  <br /> product code for purchase.
                </p>
              </div>

              <div className="filter-video__pointer-bottom">
                <ArrowCurved />
                <p>
                  Has helped to organise the
                  <br /> 922 products on offer.
                </p>
              </div>
              <div className="filter-video__video">
                <video playsInline loop={true} autoPlay="autoplay" muted>
                  <source src={videoFiltering} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div className="filter-text">
              <h4 className="filter-text__heading">
                Product filtering helping to sort through the rubbish.
              </h4>
              <p className="filter-text__description">
                How do you enable repeat customers to quickly and easily find
                the product they want and its product code? Handsome built a
                custom filtering table that does just that.{" "}
                <strong>Greater visibility</strong> for stakeholders,{" "}
                <strong>
                  faster website enquiries, improved customer service
                </strong>{" "}
                and a <strong>greater buying experience.</strong>
              </p>
            </div>
          </div>
        </section>

        <section
          className="product"
          data-sal="slide-up"
          data-sal-duration="500"
        >
          <span className="leaf-top-left">
            <LeafOne />
          </span>
          <span className="leaf-top-right">
            <LeafTwo />
          </span>
          <h4 className="product__heading">
            An opportunity for{" "}
            <span className="break">customers to learn.</span>
          </h4>
          <div className="product__video">
            <div className="screen-container">
              <img
                className="screen-container__record"
                src={gifProduct}
                alt="An opportunity for customers to learn."
              />

              <div className="pointer-top">
                <ArrowCurved />
                <p>
                  It was vital to the
                  <br />
                  client that the site
                  <br />
                  informs visitors as
                  <br />
                  much as possible.
                </p>
              </div>

              <div className="pointer-bottom">
                <ArrowCurved />
                <p>
                  The calculator highlights the
                  <br /> growing problem of
                  <br /> landfill in Australia.
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="next-btn">
          <LabeledArrow
            label="SHOW ME ANOTHER PROJECT"
            url="/our-work/apw-partners/"
          />
        </div>
        <ContactForm />
      </Wrapper>
    </Layout>
  );
};

export default SourceSeparationSystemsPage;
